<script>
import { mapActions, mapState } from 'vuex';
import { TIME_ZONE } from '@emobg/web-utils';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent } from '@/components';
import {
  getUserNotificationSchema,
  getUserNotificationsFilters,
} from './const/userNotificationTable';

export default {
  name: 'UserNotifications',
  components: {
    FilterTableComponent,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userNotifications: state => state[USER_SCOPES.notifications].data,
      isLoadingUserNotifications: state => state[USER_SCOPES.notifications].STATUS.LOADING,
    }),
    userNotificationFilters() {
      return getUserNotificationsFilters({
        operatorTimezone: this.operatorTimezone,
      });
    },
    userNotificationSchema() {
      return getUserNotificationSchema({
        operatorTimezone: this.operatorTimezone,
      });
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, ['getUserNotifications']),
    async fetchUserNotifications(params) {
      this.getUserNotifications({
        userUuid: this.user.uuid,
        params,
      });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_notifications-view"
    class="UserNotificationsList"
  >
    <h1 class="mb-2">
      Notifications
    </h1>
    <FilterTableComponent
      :data="userNotifications"
      :schema="userNotificationSchema"
      :filters="userNotificationFilters"
      :is-loading="isLoadingUserNotifications"
      is-api
      data-test-id="list"
      @api:request="fetchUserNotifications"
    />
  </div>
</template>
