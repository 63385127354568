import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { MarkdownComponent } from '@/components';
import { AVAILABLE_LANGUAGES } from '@/constants/languages';

export const getUserNotificationSchema = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    header: 'Channel',
    template: ({ notificationChannelType }) => sentenceCase(notificationChannelType),
  },
  {
    header: 'Type',
    template: ({ auditName }) => sentenceCase(auditName),
  },
  {
    header: 'Sent date',
    template: ({ createdAt }) => formatUtc(createdAt, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
  {
    header: 'Booking Id',
    template: ({ bookingFk }) => `#${bookingFk}`,
  },
  {
    header: 'Contact detail',
    template: 'notificationChannelValue',
  },
  {
    header: 'Message',
    component: MarkdownComponent,
    props: ({ message }) => ({
      content: { [AVAILABLE_LANGUAGES.en_GB]: message },
      language: AVAILABLE_LANGUAGES.en_GB,
    }),
    collapsed: true,
  },
];

export const getUserNotificationsFilters = () => [
  {
    type: 'refinementList',
    props: {
      title: 'Channel',
      attributeName: 'notificationChannelType',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'auditActionFk',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'datesRange',
    props: {
      title: 'Sent date',
      attributeName: 'createdAt',
      toggled: false,
    },
  },
];
