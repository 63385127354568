var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserNotificationsList",
      attrs: { "data-test-id": "user_notifications-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Notifications ")]),
      _c("FilterTableComponent", {
        attrs: {
          data: _vm.userNotifications,
          schema: _vm.userNotificationSchema,
          filters: _vm.userNotificationFilters,
          "is-loading": _vm.isLoadingUserNotifications,
          "is-api": "",
          "data-test-id": "list",
        },
        on: { "api:request": _vm.fetchUserNotifications },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }